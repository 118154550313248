@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    width: 100vw;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.leaflet-bar a {
    background: #b1cef6 !important;
    color: #20538D !important;
}

a.leaflet-disabled {
    background: #b1cef6 !important;
    color: #8b8b8c !important;
}

.floor-plan-selector-container {
    width: fit-content;
    height: fit-content;
    padding: 10px;
}

.floor-plan-button {
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #20538D;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #b1cef6;
    color: #FFF;
    padding: 8px;
}

.floor-plan-button-selected {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4) !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: #313943 !important;
    color: #FFF !important;
}

.map-spinner {
    width: 100%;
    height: 100%;
}